:root {
  --amplify-fonts-default-variable: "Montserrat", sans-serif;
  --amplify-colors-font-primary: hsl(var(--primary));
  --amplify-colors-primary-80: hsl(var(--secondary));
  --amplify-colors-font-hover: hsl(var(--primary));
  --amplify-components-fieldcontrol-font-size: 0.9rem;
  --amplify-components-button-border-radius: 20px;
  --amplify-components-button-link-hover-background-color: hsl(194 100% 96%);
  --amplify-components-button-hover-border-color: #00263d;
  --amplify-components-button-focus-background-color: hsl(var(--secondary));
  --amplify-components-button-primary-focus-background-color: hsl(
    var(--secondary)
  );
  --amplify-components-button-primary-active-background-color: hsl(
    var(--secondary)
  );
  --amplify-components-button-primary-hover-background-color: #f26202;
  --amplify-components-authenticator-form-padding: 1rem;
  --amplify-components-fieldcontrol-border-radius: 10px;
  --amplify-components-fieldcontrol-border-width: 2px;
  --amplify-components-fieldcontrol-focus-border-color: #000000;
  --amplify-components-authenticator-modal-background-color: 194 100% 96%;
  --amplify-components-tabs-item-active-border-color: hsl(var(--primary));
  --amplify-components-button-link-color: hsl(var(--primary));
  --amplify-components-tabs-item-hover-color: hsl(var(--primary) / 0.5);
  --amplify-components-authenticator-router-box-shadow: unset;
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-button-primary-focus-box-shadow: unset;
  --amplify-components-fieldcontrol-focus-box-shadow: none;
}

.amplify-alert {
  border-radius: 20px;
}

.amplify-divider {
  margin: 8px 0;
}

.amplify-text, .amplify-label {
  font-size: var(--amplify-font-sizes-small);
}

.amplify-label {
  font-weight: bold;
}

.amplify-alert .amplify-button--link:hover {
  background-color: transparent;
}

.amplify-tabs__item--active:hover {
  color: hsl(var(--secondary) / 0.8);
}

.amplify-field__show-password {
  border-radius: 10px;
}

@media (min-width: 768px) { 
  .login-container {
    background-image: url('/orange-gradient-shape.png');
    background-repeat: no-repeat;
    background-size: cover
  }
}